html {
  color-scheme: dark;
  --color-prettylights-syntax-comment: #8b949e;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-entity-tag: #ff7b72;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-variable: #ffa657;
  --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
  --color-prettylights-syntax-markup-deleted-bg: #67060c;
  --color-prettylights-syntax-markup-inserted-text: #aff5b4;
  --color-prettylights-syntax-markup-inserted-bg: #033a16;
  --color-prettylights-syntax-meta-diff-range: #d2a8ff;
  --color-neutral-muted: rgba(110, 118, 129, 0.4);
}

/* THIS DOES THE TRICK AND REMOVES WHITE BACKGROUND FROM ZENDESK */
iframe {
  color-scheme: light;
}

h1 {
  font-family: Mulish;
  font-size: 23px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 23px;
}

h3 {
  font-family: Mulish;
  font-size: 17px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 23px;
}

p {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}

a {
  color: inherit;
}

blockquote {
  font-family: Mulish;
  font-size: 17px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}

.recharts-legend-item {
  font-size: 0.66rem;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--color-prettylights-syntax-comment);
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.selector,
.token.constant,
.token.symbol,
.token.deleted {
  color: #ff7b72 !important;
}

.token.maybe-class-name {
  color: var(--color-prettylights-syntax-variable);
}

.token.property-access,
.token.operator,
.token.boolean,
.token.number,
.token.selector .token.class,
.token.attr-name,
.token.string,
.token.char,
.token.builtin {
  color: var(--color-prettylights-syntax-constant);
}

.token.deleted {
  color: var(--color-prettylights-syntax-markup-deleted-text);
}

.code-line .token.deleted {
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}

.token.inserted {
  color: var(--color-prettylights-syntax-markup-inserted-text);
}

.code-line .token.inserted {
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}

.token.variable {
  color: var(--color-prettylights-syntax-constant);
}

.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: var(--color-prettylights-syntax-string);
}

.token.color,
.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: var(--color-prettylights-syntax-string);
}

.token.rule,
.token.regex,
.token.important,
.token.keyword {
  color: var(--color-prettylights-syntax-keyword) !important;
}

.token.coord {
  color: var(--color-prettylights-syntax-meta-diff-range);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.punctuation {
  color: #1158c7 !important;
  font-weight: inherit !important;
}

.ygrid.crisp {
  stroke-dasharray: 4 4 !important;
}


.DateRangePicker_picker {
  z-index: 99999999 !important;
}

.fancy_spinner {
  position: relative;
  margin: auto;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  --colorA: #6257c0;
}

.fancy_spinner::before,
.fancy_spinner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border-top: 5px solid rgba(255, 255, 255, 0.95);
  filter: drop-shadow(0 0 2px var(--colorA)) drop-shadow(0 0 5px var(--colorA)) drop-shadow(0 0 10px var(--colorA)) drop-shadow(0 0 20px var(--colorA));
  -webkit-animation: rotate 3s infinite linear;
  animation: rotate 3s infinite linear;
}

.fancy_spinner::after {
  --colorA: #e00399;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.recharts-tooltip-wrapper {
  z-index: 9999;
}

.recharts-tooltip-wrapper:focus {
  outline: none;
}

/* Override onepassword autofill color */

/* select[data-com-onepassword-filled="dark"], 
textarea[data-com-onepassword-filled="dark"],
select[data-com-onepassword-filled="light"], 
textarea[data-com-onepassword-filled="light"], */
input[data-com-onepassword-filled="light"], 
input[data-com-onepassword-filled="dark"] {
  background-clip: text !important;
}

/* For text buggle for concepts highlighting */
.text-bubble {
  display: inline-block;
  padding: 2px 5px 4px 4px; 
  border-radius: 5px;
  margin: 2px;
  color: #191826;
  font-weight: 500;
}

.text-inner-bubble {
  font-size: 12px;
  background-color: #fff; 
  padding: 2px 4px; 
  border-radius: 4px;
  color: #191826;
  font-weight: 500;
}