@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?5538016');
  src: url('../font/fontello.eot?5538016#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?5538016') format('woff2'),
       url('../font/fontello.woff?5538016') format('woff'),
       url('../font/fontello.ttf?5538016') format('truetype'),
       url('../font/fontello.svg?5538016#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?5538016#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-archive:before { content: '\e800'; } /* '' */
.icon-addtonarrative:before { content: '\e801'; } /* '' */
.icon-percent:before { content: '\e802'; } /* '' */
.icon-plusminus:before { content: '\e803'; } /* '' */
.icon-editpencil:before { content: '\e804'; } /* '' */
.icon-sigma:before { content: '\e805'; } /* '' */
.icon-pulse:before { content: '\e806'; } /* '' */
.icon-sidebarlayout:before { content: '\e807'; } /* '' */
.icon-full-widthlayout:before { content: '\e808'; } /* '' */
.icon-sortdown:before { content: '\e809'; } /* '' */
.icon-sortup:before { content: '\e80a'; } /* '' */
.icon-boxes:before { content: '\e80b'; } /* '' */
.icon-eyefilled:before { content: '\e80c'; } /* '' */
.icon-arrowturnright:before { content: '\e80d'; } /* '' */
.icon-database:before { content: '\e80e'; } /* '' */
.icon-linegraph:before { content: '\e80f'; } /* '' */
.icon-lifepreserver:before { content: '\e810'; } /* '' */
.icon-tools:before { content: '\e811'; } /* '' */
.icon-questionfilled:before { content: '\e812'; } /* '' */
.icon-plugoutline:before { content: '\e813'; } /* '' */
.icon-gridfill:before { content: '\e814'; } /* '' */
.icon-networkoutline:before { content: '\e815'; } /* '' */
.icon-dashboard:before { content: '\e816'; } /* '' */
.icon-bricks:before { content: '\e817'; } /* '' */
.icon-magicwand:before { content: '\e818'; } /* '' */
.icon-pause:before { content: '\e819'; } /* '' */
.icon-livechat:before { content: '\e81a'; } /* '' */
.icon-refreshcircle:before { content: '\e81b'; } /* '' */
.icon-refreshschedule:before { content: '\e81c'; } /* '' */
.icon-anomalous2:before { content: '\e81d'; } /* '' */
.icon-hyperlink:before { content: '\e81e'; } /* '' */
.icon-calendar:before { content: '\e81f'; } /* '' */
.icon-location:before { content: '\e820'; } /* '' */
.icon-contextmenutoggle:before { content: '\e821'; } /* '' */
.icon-network:before { content: '\e822'; } /* '' */
.icon-timeline:before { content: '\e823'; } /* '' */
.icon-clock:before { content: '\e824'; } /* '' */
.icon-info:before { content: '\e825'; } /* '' */
.icon-warningoutline:before { content: '\e826'; } /* '' */
.icon-warningsolid:before { content: '\e827'; } /* '' */
.icon-plug:before { content: '\e828'; } /* '' */
.icon-redditfilled:before { content: '\e829'; } /* '' */
.icon-redditoutline:before { content: '\e82a'; } /* '' */
.icon-usercircle:before { content: '\e82b'; } /* '' */
.icon-partisanship:before { content: '\e82c'; } /* '' */
.icon-harmfulposts:before { content: '\e82d'; } /* '' */
.icon-knownhoax:before { content: '\e82e'; } /* '' */
.icon-anomalous:before { content: '\e82f'; } /* '' */
.icon-bot:before { content: '\e830'; } /* '' */
.icon-toxic:before { content: '\e831'; } /* '' */
.icon-neutral:before { content: '\e832'; } /* '' */
.icon-thumbsup:before { content: '\e833'; } /* '' */
.icon-thumbsdown:before { content: '\e834'; } /* '' */
.icon-doublechevronleft:before { content: '\e835'; } /* '' */
.icon-comment:before { content: '\e836'; } /* '' */
.icon-doublechevrondown:before { content: '\e837'; } /* '' */
.icon-doublechevronup:before { content: '\e838'; } /* '' */
.icon-doublechevronright:before { content: '\e839'; } /* '' */
.icon-closeline:before { content: '\e83a'; } /* '' */
.icon-closecirclefilled:before { content: '\e83b'; } /* '' */
.icon-checkmarkcirclefilled:before { content: '\e83c'; } /* '' */
.icon-minusfilled:before { content: '\e83d'; } /* '' */
.icon-arrowleft:before { content: '\e83e'; } /* '' */
.icon-plusfilled:before { content: '\e83f'; } /* '' */
.icon-arrowright:before { content: '\e840'; } /* '' */
.icon-duplicate:before { content: '\e841'; } /* '' */
.icon-settings2:before { content: '\e842'; } /* '' */
.icon-arrowreturnright:before { content: '\e843'; } /* '' */
.icon-export:before { content: '\e844'; } /* '' */
.icon-logout:before { content: '\e845'; } /* '' */
.icon-openinnewtab:before { content: '\e846'; } /* '' */
.icon-copy:before { content: '\e847'; } /* '' */
.icon-originalpost:before { content: '\e848'; } /* '' */
.icon-cluster:before { content: '\e849'; } /* '' */
.icon-image:before { content: '\e84a'; } /* '' */
.icon-reset:before { content: '\e84b'; } /* '' */
.icon-quote:before { content: '\e84c'; } /* '' */
.icon-sharefill:before { content: '\e84d'; } /* '' */
.icon-documentline:before { content: '\e84e'; } /* '' */
.icon-originalfill:before { content: '\e84f'; } /* '' */
.icon-verified:before { content: '\e850'; } /* '' */
.icon-documentfilled:before { content: '\e851'; } /* '' */
.icon-cohorts:before { content: '\e852'; } /* '' */
.icon-questioncircleoutline:before { content: '\e853'; } /* '' */
.icon-questioncirclefill:before { content: '\e854'; } /* '' */
.icon-emotions:before { content: '\e855'; } /* '' */
.icon-sentiment:before { content: '\e856'; } /* '' */
.icon-search:before { content: '\e857'; } /* '' */
.icon-engagements:before { content: '\e858'; } /* '' */
.icon-user:before { content: '\e859'; } /* '' */
.icon-twitter:before { content: '\e85a'; } /* '' */
.icon-analyze:before { content: '\e85b'; } /* '' */
.icon-posts:before { content: '\e85c'; } /* '' */
.icon-expand:before { content: '\e85d'; } /* '' */
.icon-users:before { content: '\e85e'; } /* '' */
